import React from "react";
import { isIOS, isAndroid } from "react-device-detect";
import { APP_APPLE_URL, APP_GOOGLE_URL } from "pages/getLicenceKey/constants";
import Loader from "components/loader";
import appleStore from "pages/getLicenceKey/assets/appleStore.png";
import googlePlay from "pages/getLicenceKey/assets/googlePlay.png";

const GoToMarketPage = () => {
  if (isIOS) {
    window.location.href = APP_APPLE_URL;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <a href={APP_APPLE_URL} target="_blank" rel="noreferrer">
          <img src={appleStore} alt="" />
        </a>
      </div>
    );
  }
  if (isAndroid) {
    window.location.replace(APP_GOOGLE_URL);
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <a href={APP_GOOGLE_URL} target="_blank" rel="noreferrer">
          <img src={googlePlay} alt="" />
        </a>
      </div>
    );
  }

  return <Loader />;
};

export default GoToMarketPage;
