import React from "react";
import googlePlay from "./assets/googlePlay.png";
import appleStore from "./assets/appleStore.png";
import s from "./styles.module.scss";
import { APP_APPLE_URL, APP_GOOGLE_URL } from "pages/getLicenceKey/constants";

const MobileLinks: React.FC = () => (
  <div className={s.mobileLinks}>
    <a href={APP_APPLE_URL} target="_blank" rel="noreferrer">
      <img src={appleStore} alt="" />
    </a>
    <a href={APP_GOOGLE_URL} target="_blank" rel="noreferrer">
      <img src={googlePlay} alt="" />
    </a>
  </div>
);

export default MobileLinks;
