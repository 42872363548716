import React, { MouseEventHandler, useMemo } from "react";
import * as CSS from "csstype";

import { Icons } from "./constants";

export type IconNames = keyof typeof Icons;

export interface IconProps {
  name: IconNames;
  size?: number | {
    width?: number | string;
    height?: number | string;
  };
  fill?: string;
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
}

export const Icon: React.FC<IconProps> = ({
                                            fill = "#D1D3D5",
                                            size = 24,
                                            name,
                                            onClick,
                                            className,
                                          }) => {
  const Tag = Icons[name];

  const tagStyle: CSS.Properties = useMemo(
    () => ({
      // eslint-disable-next-line no-nested-ternary
      width: typeof size !== 'object' ? `${size}px` : typeof size.width === 'string' ? size.width : `${size.width}px`,
      // eslint-disable-next-line no-nested-ternary
      height: typeof size !== 'object' ? `${size}px` : typeof size.height === 'string' ? size.height: `${size.height}px`,
      fill,
      cursor: onClick ? "pointer" : "inherit",
      strokeWidth: "10px",
    }),
    [size, onClick, fill]
  );

  return (
    <Tag name={name} style={tagStyle} onClick={onClick} className={className} />
  );
};
