import { callFunction } from "app/firebase";
import { LicenseKeyInDb } from "sport-app-types";

export async function getLicenseKey(
  organizationId: string
): Promise<LicenseKeyInDb | null> {
  return callFunction<
    { organizationId: string },
    {
      licenseKey: LicenseKeyInDb | null;
    }
  >("getLicenseKey")({
    organizationId,
  }).then((d) => d.data.licenseKey);
}
