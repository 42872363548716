import React from "react";
import { ClipLoader } from "react-spinners";

const Loader: React.FC<{ size?: "l" | "s" }> = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
    }}
  >
    <ClipLoader size={40} color={"#FFD84E"} />
  </div>
);

export default Loader;
