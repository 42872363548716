import React, { useReducer, useMemo, useCallback, useContext } from "react";

interface IAuthContext {
  isAuth: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = React.createContext<IAuthContext>({
  isAuth: true,
  login: () => {},
  logout: () => {},
});

type AuthReducerActions =
  | {
      type: "login";
    }
  | {
      type: "logout";
    };

function authReducer(
  state: {
    isAuth: boolean;
  },
  action: AuthReducerActions
) {
  switch (action.type) {
    case "login":
      return {
        isAuth: true,
      };
    case "logout":
      return {
        isAuth: false,
      };
    default:
      return state;
  }
}

export const AuthContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    isAuth: false,
  });

  const login = useCallback(() => {
    dispatch({
      type: "login",
    });
  }, []);

  const logout = useCallback(() => {
    dispatch({
      type: "logout",
    });
  }, []);

  const memoAuthStateValue = useMemo(
    () => ({
      isAuth: state.isAuth,
      login,
      logout,
    }),
    [state, logout, login]
  );

  return (
    <AuthContext.Provider value={memoAuthStateValue}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
