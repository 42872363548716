import { RouteProps } from "react-router-dom";
import { routeUrlFns, RouteUrlFnsNames } from "app/routes/routeUrlFns";
import React from "react";
import GetLicenseKeyPage from "pages/getLicenceKey";
import GoToMarketPage from "pages/goToMarket";
import UpdateOrganizationsInFbPage from "pages/updateOrganizationsInFB";

// export const routesDesktopGuest: RouteProps[] = [
//   {
//     path: routeUrlFns[RouteUrlFnsNames.auth](),
//     component: AuthPageDesktop,
//   },
// ];

export const routesDesktopAuthorized: RouteProps[] = [
  {
    path: routeUrlFns[RouteUrlFnsNames.getLicenseKey](),
    component: GetLicenseKeyPage,
  },
  {
    path: routeUrlFns[RouteUrlFnsNames.goToMarket](),
    component: GoToMarketPage,
  },
  {
    path: routeUrlFns[RouteUrlFnsNames.syncData](),
    component: UpdateOrganizationsInFbPage,
  },
];
