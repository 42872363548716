import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "uikit/icon";
import { getLicenseKey } from "app/api/getData/getLicenceKey";
import s from "pages/getLicenceKey/styles.module.scss";
import biceps from "app/assets/biceps71.png";
import activateKeyScreenshot from "pages/getLicenceKey/assets/activateKeyScreenshot.png";
import giftImg from "./assets/gift.png";
import { LicenseKeyInDb } from "sport-app-types";
import { useLocalStorage } from "lib/hooks/useLocalStorage";
import dayjs from "dayjs";
import Loader from "components/loader";
import { updateLicenseKeyInDb } from "app/api/updateData/updateLicenseKey";
import { isMobile } from "react-device-detect";
import MobileLinks from "pages/getLicenceKey/mobileLinks";
import DesktopLinks from "pages/getLicenceKey/desktopLinks";
import { copyToClipboard } from "lib/copyToClipboard";
import { toast } from "react-hot-toast";
import { logosOfOrganizations } from "pages/getLicenceKey/constants";

const GetLicenseKeyPage: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const organizationLogo = logosOfOrganizations[organizationId];

  const [keyLoading, setKeyLoading] = useState(false);

  const [getLicenseKeyDate, setGetLicenseKeyDate] = useLocalStorage<
    string | null
  >("getLicenseKeyDate", dayjs().toISOString());

  const [licenseKey, setLicenseKey] = useLocalStorage<LicenseKeyInDb | null>(
    "savedLicenseKey",
    null
  );

  const loadLicenseKey = async () => {
    try {
      setKeyLoading(true);
      const key = await getLicenseKey(organizationId);
      await updateLicenseKeyInDb({
        ...key,
        requested: true,
        requestedAt: dayjs().toISOString(),
      } as LicenseKeyInDb);
      setLicenseKey(key);
      try {
        await copyToClipboard(licenseKey.licenseKey);
        toast.success("Лицензионный ключ скопирован в буфер обмена");
      } catch (e) {
        toast.success("Нажмите на ключ, чтобы скопировать");
        console.warn(e);
      }
      setKeyLoading(false);
      setGetLicenseKeyDate(dayjs().toISOString());
    } catch (e) {
      setKeyLoading(false);
    }
  };

  useEffect(() => {
    if (dayjs().isAfter(dayjs(getLicenseKeyDate).add(1, "h"))) {
      setLicenseKey(null);
    }
  }, []);

  const licenceKeyOrLoadLicenceKeyBtn = licenseKey ? (
    <div
      className={s.licenceKey}
      onClick={async () => {
        await copyToClipboard(licenseKey.licenseKey);
        toast.success("Лицензионный ключ скопирован в буфер обмена");
      }}
    >
      {licenseKey.licenseKey}
    </div>
  ) : (
    <button className={s.generateButton} onClick={loadLicenseKey}>
      Сгенерировать
    </button>
  );

  return (
    <div className={s.container}>
      <div className={s.row}>
        <div>
          <a href="http://8app.ru/" target="_blank" rel="noreferrer">
            <Icon
              name={"vsmrkLogoWithDescription"}
              size={{
                height: "auto",
                width: 300,
              }}
            />
          </a>

          <div className={s.wowText}>
            УХ ТЫ! Тебе подарок <img src={giftImg} alt="" /> от компании{" "}
            <img
              src={biceps}
              alt={""}
              style={{
                margin: "auto",
              }}
              className={s.bicepsImg}
            />
          </div>
        </div>
        <div>
          <img src={organizationLogo} alt={""} className={s.organizationLogo} />
        </div>
      </div>

      <div className={s.stepsRow}>
        <div className={s.step}>
          <div className={s.stepNum}>1</div>
          <div>
            Скачай приложение “Восьмёрка”. Здесь все тренировки идут в прямом
            эфире и с реальными тренерами
          </div>
          <div>{isMobile ? <MobileLinks /> : <DesktopLinks />}</div>
        </div>
        <div className={s.step}>
          <div className={s.stepNum}>2</div>
          <div>
            Зарегистрируйся в приложении любым удобным способом. Перейди на
            страницу активации подписки и включи слайдер “У меня есть промокод”
          </div>
          <div>
            <img
              src={activateKeyScreenshot}
              alt={""}
              className={s.activateKeyScreenshot}
            />
          </div>
        </div>
        <div className={`${s.step} ${s.stretch}`}>
          <div className={s.stepNum}>3</div>
          <div>
            Сгенерируй личный лицензионный ключ. Ты можешь активировать его в
            день генерации до 00:00. Он даст тебе подписку на все тренировки в
            приложении на 31 день.
          </div>
          <div className={s.generateWrapper}>
            {keyLoading ? <Loader /> : licenceKeyOrLoadLicenceKeyBtn}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetLicenseKeyPage;
