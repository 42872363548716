export enum RouteUrlFnsNames {
  auth = "auth",
  getLicenseKey = "getLicenseKey",
  goToMarket = "goToMarket",
  syncData = "syncData",
}

export const routeUrlFns: {
  [key in RouteUrlFnsNames]: (...params: any[]) => string;
} = {
  [RouteUrlFnsNames.auth]: () => "/auth",
  [RouteUrlFnsNames.getLicenseKey]: (organizationId?: string) =>
    `/licenseKey/${organizationId || ":organizationId"}`,
  [RouteUrlFnsNames.goToMarket]: () => `/goToMarket`,
  [RouteUrlFnsNames.syncData]: () => "/syncDataS6807203058450243",
};
