import React, { useEffect, useState } from "react";
import { setOrganizationsToFb } from "pages/getLicenceKey/script";
import Loader from "components/loader";

const UpdateOrganizationsInFbPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const syncData = async () => {
    setLoading(true);
    try {
      await setOrganizationsToFb();
    } catch (e) {
      setError(e);
      console.warn("Не удалось выгрузить информацию! ", e);
    }
    setLoading(false);
  };

  const okOrError = error ? (
    <div onClick={syncData}>
      Произошла ошибка! Обратитесь к тех поддержке. Нажмите на текст, чтобы
      повторить попытку.{" "}
    </div>
  ) : (
    <div>Ok!)</div>
  );

  useEffect(() => {
    syncData();
  }, []);

  return loading ? (
    <div
      style={{
        marginTop: 100,
      }}
    >
      <Loader />
    </div>
  ) : (
    okOrError
  );
};

export default UpdateOrganizationsInFbPage;
