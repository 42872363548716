import React from "react";
import s from "pages/getLicenceKey/styles.module.scss";
import vsmrkQrInstall from "app/assets/vsmrkQrInstall.png";

const DesktopLinks = () => (
  <>
    <img src={vsmrkQrInstall} alt={""} className={s.qrCode} />
    <div className={s.stepGrayText}>
      Наведи камеру на qr код, чтобы скачать приложение
    </div>
  </>
);
export default DesktopLinks;
