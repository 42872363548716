export const APP_APPLE_URL = "https://vsmrk.onelink.me/DSPD/254eb6d6";
export const APP_GOOGLE_URL = "https://vsmrk.onelink.me/DSPD/254eb6d6";

export const logosOfOrganizations: Record<string, string> = {
  "030c897e-ef08-48de-8816-1c3ea3feec8d":
    "https://firebasestorage.googleapis.com/v0/b/vsmrkapp.appspot.com/o/organization%2FDA-logo-color-rgb.png?alt=media&token=fa682d83-b813-4d98-b22e-ce052bc5494d",
  "3a6c6cca-f11e-45af-8b4c-ae9d589ea549":
    "https://firebasestorage.googleapis.com/v0/b/vsmrkapp.appspot.com/o/organization%2Flogo%208.png?alt=media&token=9b23ece9-51cf-4cad-918f-ee7edd59ef18",
  "3deb560c-2e76-4b4d-ac9e-bd31a5929ebf":
    "https://firebasestorage.googleapis.com/v0/b/vsmrkapp.appspot.com/o/organization%2FPiratesTravelRU-logo.jpg?alt=media&token=ddbe344b-f72b-4e41-8ec3-4a9d5f37609e",
  "651f354c-27f7-4dbb-99d1-df2747afcf96":
    "https://firebasestorage.googleapis.com/v0/b/vsmrkapp.appspot.com/o/organization%2Fwilix-logo.png?alt=media&token=0216e309-cb1f-49d1-afb9-024368cb2103",
  "a572162e-ed5f-4c5d-8f0a-71f492597e07":
    "https://firebasestorage.googleapis.com/v0/b/vsmrkapp.appspot.com/o/organization%2FqvantLogoRusBlack.png?alt=media&token=0a801655-98eb-44b6-a9e6-8573e640810d",
  "34c3df59-1d2b-4ef1-9b71-3462261b6278":
    "https://firebasestorage.googleapis.com/v0/b/vsmrkapp.appspot.com/o/organization%2Flogo%208.png?alt=media&token=9b23ece9-51cf-4cad-918f-ee7edd59ef18",
  "a093c865-06f6-4eac-b7d8-4230f9b77a62":
    "https://firebasestorage.googleapis.com/v0/b/vsmrkapp.appspot.com/o/organization%2FLogo_DD_gorizont_2_2.png?alt=media&token=9c35104c-1340-4849-8390-b2cb1cb90169",
  "5f02ca2f-b582-4e67-822f-2d974ff1cc8d":
    "https://firebasestorage.googleapis.com/v0/b/vsmrkapp.appspot.com/o/organization%2FX5Group-logo.png?alt=media&token=f53046e4-a75a-494d-83d2-287a3804e08f",
};
