import { getDocRef, getFSBatch } from "app/firebase";
import { LicenseKeyInDb } from "sport-app-types";

export async function updateLicenseKeyInDb(newInfo: LicenseKeyInDb) {
  const batch = getFSBatch();
  batch.set(
    getDocRef(
      `/organizations/${newInfo.organizationId}/licenseKeys/${newInfo.licenseKey}`
    ),
    {
      newInfo,
    }
  );

  return batch.commit();
}
