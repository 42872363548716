import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { getAuth } from "firebase/auth";
import "firebase/compat/firestore";
import devConfig from "./devConfig.json";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc as getDocDefault,
  doc,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable, getFunctions } from "firebase/functions";

// const firebaseApp = firebase.initializeApp(isProd ? config : devConfig);
const firebaseApp = firebase.initializeApp(devConfig);

const firebaseFunctions = getFunctions(firebaseApp);
const firestoreDb = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const firestore = firebase.firestore();

export function getFSBatch() {
  return writeBatch(firestoreDb);
}

export function callFunction<Params = unknown, Result = unknown>(name: string) {
  return httpsCallable<Params, Result>(firebaseFunctions, name);
}

export function getCollectionRef(collectionUrl: string) {
  return collection(firestoreDb, collectionUrl);
}

export function getCollection(collectionUrl: string) {
  return getDocs(getCollectionRef(collectionUrl))
    .then((d) => d.docs)
    .then((collectionRes) =>
      collectionRes.map((colDocument) => colDocument.data())
    );
}

export function getDocRef(docUrl: string) {
  return doc(firestoreDb, docUrl);
}

export function getDoc(docUrl: string) {
  return getDocDefault(getDocRef(docUrl)).then((d) => d.data());
}

export { firebase };
