import React from "react";
import { Route, Switch } from "react-router-dom";
import { routesDesktopAuthorized } from "app/routes/desktop/routesDesktop";
import { useAuth } from "app/auth/authContext";

const RoutesComponentDesktop: React.FC = () => {
  const { isAuth } = useAuth();

  switch (isAuth) {
    // case false:
    //   return (
    //     <Switch>
    //       <Route>
    //         {routesDesktopGuest.map((props) => (
    //           <Route {...props} />
    //         ))}
    //         <Redirect to={routeUrlFns[RouteUrlFnsNames.auth]()} />
    //       </Route>
    //     </Switch>
    //   );
    case true:
      return (
        <Switch>
          <Route>
            {routesDesktopAuthorized.map((props) => (
              <Route {...props} />
            ))}
          </Route>
        </Switch>
      );
    default:
      return (
        <Switch>
          <Route>
            {routesDesktopAuthorized.map((props) => (
              <Route {...props} />
            ))}
          </Route>
        </Switch>
      );
  }
};

export default RoutesComponentDesktop;
