import axios from "axios";
import { B2BOrganizationInDb } from "sport-app-types";
import { getDocRef, getFSBatch } from "app/firebase";

export async function setOrganizationsToFb() {
  let batch = getFSBatch();
  const organizationsBatch = getFSBatch();

  const organizations = await axios
    .get<B2BOrganizationInDb[]>(
      "https://api.vsmrk.com/v1/operator/licenseKey/organization88ajkip3"
    )
    .then((d) => d.data);

  let i = 0;
  let batchNum = 1;
  const batches: Promise<any>[] = [];
  organizations.forEach(({ licenseKeys, ...organization }) => {
    organizationsBatch.set(
      getDocRef(`/organizations/${organization.id}`),
      organization
    );

    licenseKeys?.forEach(async (key) => {
      if (i > 498) {
        i = 0;
        const batchCommit = batch.commit();
        batches.push(batchCommit);
        console.log("Batch ", batchNum);
        batch = getFSBatch();
        batchNum += 1;
      }
      i += 1;

      batch.set(
        getDocRef(
          `/organizations/${organization.id}/licenseKeys/${key.licenseKey}`
        ),
        {
          ...key,
          requested: false,
        }
      );
    });
  });

  batches.push(batch.commit());
  await organizationsBatch.commit();

  return Promise.all(batches);
}
